import { useMemo, type PropsWithChildren, type ReactNode } from "react";
import { useBerechtigungen } from "./PermissionsContext";

export type Expression = {
  berechtigung: string;
  fallback?: ReactNode;
};

export type Props = PropsWithChildren<Expression>;

export default function Guard({ children, fallback, ...props }: Props) {
  const permitted = useBerechtigt(props);
  return permitted ? <>{children}</> : <>{fallback}</> ?? null;
}

export function useBerechtigt({ berechtigung }: Expression): boolean {
  const berechtigungen = useBerechtigungen();

  return useMemo(() => {
    if (berechtigung) {
      return berechtigungen.includes(berechtigung);
    }

    return false;
  }, [berechtigungen, berechtigung]);
}
