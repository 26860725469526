import {
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { version } from "../config";
import Guard from "./Guard";
import { NavButton } from "./NavButton";
import {
  AuftragIcon,
  BenutzerIcon,
  ServoIcon,
  ServoTypIcon,
  TestStationIcon,
  TestSzenarioAusfuehrungIcon,
  TestSzenarioIcon,
} from "./icons";

export function MainMenu({ close }: { close: () => void }) {
  return (
    <>
      <List sx={{ flex: 1 }}>
        <Guard berechtigung="auftrag:liste">
          <NavButton href="/auftraege" onClick={close}>
            <ListItemIcon>
              <AuftragIcon />
            </ListItemIcon>
            <ListItemText primary="Aufträge" />
          </NavButton>
        </Guard>
        <Guard berechtigung="test-szenario-ausfuehrung:liste">
          <NavButton href="/ausfuehrungen" onClick={close}>
            <ListItemIcon>
              <TestSzenarioAusfuehrungIcon />
            </ListItemIcon>
            <ListItemText primary="Tests" />
          </NavButton>
        </Guard>
        <Guard berechtigung="servo:liste">
          <NavButton href="/servos" onClick={close}>
            <ListItemIcon>
              <ServoIcon />
            </ListItemIcon>
            <ListItemText primary="Servos" />
          </NavButton>
        </Guard>
        <Guard berechtigung="servo-typ:liste">
          <NavButton href="/servo-typen" onClick={close}>
            <ListItemIcon>
              <ServoTypIcon />
            </ListItemIcon>
            <ListItemText primary="Servo-Typen" />
          </NavButton>
        </Guard>
        <Guard berechtigung="test-station:liste">
          <NavButton href="/test-stationen" onClick={close}>
            <ListItemIcon>
              <TestStationIcon />
            </ListItemIcon>
            <ListItemText primary="Test-Stationen" />
          </NavButton>
        </Guard>
        <Guard berechtigung="test-szenario:liste">
          <NavButton href="/test-szenarios" onClick={close}>
            <ListItemIcon>
              <TestSzenarioIcon />
            </ListItemIcon>
            <ListItemText primary="Test-Szenarios" />
          </NavButton>
        </Guard>
      </List>
      <List>
        <Divider />
        <Guard berechtigung="benutzer:liste">
          <NavButton href="/benutzer" onClick={close}>
            <ListItemIcon>
              <BenutzerIcon />
            </ListItemIcon>
            <ListItemText primary="Benutzer" />
          </NavButton>
        </Guard>
        <ListItem>
          <ListItemText
            inset
            primary={<>Version {version}</>}
            primaryTypographyProps={{ variant: "caption" }}
          />
        </ListItem>
      </List>
    </>
  );
}
