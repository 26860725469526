import { QueryClient, QueryClientProvider } from "react-query";
import { AuthProvider } from "./auth";
import { BenutzerProvider } from "./benutzer";
import GlobalError from "./components/GlobalError";
import GlobalLoading from "./components/GlobalLoading";
import SuspenseBoundary from "./components/SuspenseBoundary";
import HelmetProvider from "./helmet";
import { OptionenProvider } from "./optionen";
import Pages from "./pages";
import { SystemInformationenProvider } from "./system-informationen";
import { ThemeProvider } from "./theme";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      suspense: true,
      retry: false,
    },
  },
});

export default function Test() {
  return (
    <ThemeProvider>
      <SuspenseBoundary fallback={<GlobalLoading />} errorComp={GlobalError}>
        <HelmetProvider>
          <QueryClientProvider client={queryClient}>
            <SystemInformationenProvider>
              <AuthProvider>
                <BenutzerProvider>
                  <OptionenProvider>
                    <Pages />
                  </OptionenProvider>
                </BenutzerProvider>
              </AuthProvider>
            </SystemInformationenProvider>
          </QueryClientProvider>
        </HelmetProvider>
      </SuspenseBoundary>
    </ThemeProvider>
  );
}
