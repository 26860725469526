import { Box, Card, CardContent, Stack, Typography } from "@mui/material";
import { type ReactNode } from "react";
import Logo from "./Logo";

export default function Global({ children }: { children: ReactNode }) {
  return (
    <Box
      sx={{
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "grey.300",
        background: (theme) =>
          `radial-gradient(circle at 18.7% 37.8%, ${theme.palette.common.white} 0%, ${theme.palette.primary.light} 90%)`,
      }}
    >
      <Card sx={{ minWidth: "15rem" }}>
        <CardContent>
          <Stack spacing={2} alignItems="center" sx={{ pt: 2 }}>
            <Box
              component={Logo}
              sx={{ width: "7rem", color: "primary.main" }}
            />
            <Typography variant="h5">Volzalyzer</Typography>
            {children}
          </Stack>
        </CardContent>
      </Card>
    </Box>
  );
}
