import {
  Box,
  Drawer,
  LinearProgress,
  Toolbar,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useCallback, useState } from "react";
import { Outlet } from "react-router";
import { MainMenu } from "./MainMenu";
import Nav from "./Nav";
import SuspenseBoundary from "./SuspenseBoundary";

const drawerWidth = 240;

export default function Layout() {
  const theme = useTheme();
  const mobile = !useMediaQuery(
    `(min-width: ${theme.breakpoints.values.sm}px)`
  );
  const [open, setOpen] = useState(false);
  const close = useCallback(() => setOpen(false), []);

  return (
    <>
      <Nav mobile={mobile} open={open} setOpen={setOpen} />
      <Box sx={{ display: "flex" }}>
        <Drawer
          variant={mobile ? "temporary" : "permanent"}
          open={mobile && open}
          onClose={close}
          PaperProps={{ sx: { width: drawerWidth } }}
          sx={{ width: drawerWidth }}
        >
          <Toolbar />
          <MainMenu close={close} />
        </Drawer>
        <Box sx={{ flexGrow: 1 }}>
          <Toolbar />
          <SuspenseBoundary fallback={<LinearProgress />}>
            <Box component="main" sx={{ my: 2 }}>
              <Outlet />
            </Box>
          </SuspenseBoundary>
        </Box>
      </Box>
    </>
  );
}
