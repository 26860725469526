import { createContext, useContext, type ReactNode } from "react";
import { useQuery } from "react-query";
import { type AlleOptionen } from "./api";
import { useGetApi } from "./data";

const Context = createContext<AlleOptionen | undefined>(undefined);

export function OptionenProvider({ children }: { children: ReactNode }) {
  const getApi = useGetApi();
  const options = useQuery(
    "optionen",
    async () => (await getApi()).system.getOptionen(),
    { staleTime: 5 * 60 * 1000 }
  ).data!;

  return <Context.Provider value={options}>{children}</Context.Provider>;
}

export function useAlleOptionen(): AlleOptionen {
  const context = useContext(Context);
  if (!context) {
    throw new Error("useOptionen must be used within a OptionenProvider");
  }
  return context;
}

export function useOptionen<T extends keyof AlleOptionen>(
  typ: T
): AlleOptionen[T] {
  return useAlleOptionen()[typ];
}
