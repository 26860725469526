import { useEffect } from "react";
import { useSignIn } from "../auth";
import GlobalLoading from "./GlobalLoading";

export default function SignIn() {
  const signIn = useSignIn();
  useEffect(() => {
    signIn();
  }, [signIn]);

  return <GlobalLoading />;
}
