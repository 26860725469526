/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ID } from '../models/ID';
import type { SetzeTestStation } from '../models/SetzeTestStation';
import type { TestStationMitBerechtigungen } from '../models/TestStationMitBerechtigungen';
import type { TestStationSeite } from '../models/TestStationSeite';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class TestStationenService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @param seite Der 0-basierte Index der Seite. Per default wird die erste Seite geladen.
   *
   * @param max Die maximale Anzahl an Ergebnissen pro Seite.
   * Wenn dieser Parameter nicht angegeben oder negativ ist, werden alle Ergebnisse ab der angefragten Seite geladen.
   *
   * @returns TestStationSeite Liste der Test Stationen
   * @throws ApiError
   */
  public sucheTestStationen(
    seite?: number,
    max: number = 100,
  ): CancelablePromise<TestStationSeite> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/test-stationen',
      query: {
        'seite': seite,
        'max': max,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * @param testStationId
   * @returns TestStationMitBerechtigungen die Test Station
   * @throws ApiError
   */
  public getTestStation(
    testStationId: ID,
  ): CancelablePromise<TestStationMitBerechtigungen> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/test-stationen/{testStationId}',
      path: {
        'testStationId': testStationId,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * @param testStationId
   * @param requestBody
   * @returns TestStationMitBerechtigungen die aktualisierte Test Station
   * @throws ApiError
   */
  public setzeTestStation(
    testStationId: ID,
    requestBody: SetzeTestStation,
  ): CancelablePromise<TestStationMitBerechtigungen> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/test-stationen/{testStationId}',
      path: {
        'testStationId': testStationId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

}
