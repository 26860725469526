import { lazy, useMemo } from "react";
import { type RouteObject } from "react-router";
import { useBerechtigt } from "../../components/Guard";

const TestStationListe = lazy(() => import("./TestStationListe"));
const TestStationDialog = lazy(() => import("./TestStationDialog"));

export function useTestStationRoutes(): Array<RouteObject> {
  const enabled = useBerechtigt({ berechtigung: "test-station:liste" });

  return useMemo(
    () =>
      enabled
        ? [
            {
              path: "test-stationen",
              element: <TestStationListe />,
              children: [
                { path: "neu", element: <TestStationDialog /> },
                { path: ":testStationId", element: <TestStationDialog /> },
              ],
            },
          ]
        : [],
    [enabled]
  );
}
