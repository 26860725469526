import { lazy, useMemo } from "react";
import { type RouteObject } from "react-router";
import { useBerechtigt } from "../../components/Guard";

const TestSzenarioListe = lazy(() => import("./TestSzenarioListe"));
const TestSzenarioDetails = lazy(() => import("./TestSzenarioDetails"));
const TestSzenarioWrapper = lazy(() => import("./TestSzenarioWrapper"));
const TestSzenarioBearbeiten = lazy(() => import("./bearbeiten"));
const TestSzenarioVersionDetails = lazy(
  () => import("./TestSzenarioVersionDetails")
);

export function useTestSzenarioRoutes(): Array<RouteObject> {
  const enabled = useBerechtigt({ berechtigung: "test-szenario:liste" });

  return useMemo(
    () =>
      enabled
        ? [
            {
              path: "test-szenarios",
              children: [
                { index: true, element: <TestSzenarioListe /> },
                { path: "neu", element: <TestSzenarioListe /> },
                {
                  path: ":testSzenarioId",
                  element: <TestSzenarioWrapper />,
                  children: [
                    { index: true, element: <TestSzenarioDetails /> },
                    { path: "bearbeiten", element: <TestSzenarioBearbeiten /> },
                    {
                      path: "versionen",
                      children: [
                        {
                          path: ":versionId",
                          element: <TestSzenarioVersionDetails />,
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ]
        : [],
    [enabled]
  );
}
