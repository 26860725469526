/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ID } from '../models/ID';
import type { ServoMitBerechtigungen } from '../models/ServoMitBerechtigungen';
import type { ServoSeite } from '../models/ServoSeite';
import type { ServoTypMitBerechtigungen } from '../models/ServoTypMitBerechtigungen';
import type { ServoTypSeite } from '../models/ServoTypSeite';
import type { SetzeServoTyp } from '../models/SetzeServoTyp';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ServoService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @param seite Der 0-basierte Index der Seite. Per default wird die erste Seite geladen.
   *
   * @param max Die maximale Anzahl an Ergebnissen pro Seite.
   * Wenn dieser Parameter nicht angegeben oder negativ ist, werden alle Ergebnisse ab der angefragten Seite geladen.
   *
   * @returns ServoTypSeite Liste der Servo-Typen
   * @throws ApiError
   */
  public sucheServoTypen(
    seite?: number,
    max: number = 100,
  ): CancelablePromise<ServoTypSeite> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/servo-typen',
      query: {
        'seite': seite,
        'max': max,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * @param servoTypId
   * @returns ServoTypMitBerechtigungen der Servo-Typ
   * @throws ApiError
   */
  public getServoTyp(
    servoTypId: ID,
  ): CancelablePromise<ServoTypMitBerechtigungen> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/servo-typen/{servoTypId}',
      path: {
        'servoTypId': servoTypId,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * @param servoTypId
   * @param requestBody
   * @returns ServoTypMitBerechtigungen der aktualisierte Servo-Typ
   * @throws ApiError
   */
  public setzeServoTyp(
    servoTypId: ID,
    requestBody: SetzeServoTyp,
  ): CancelablePromise<ServoTypMitBerechtigungen> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/servo-typen/{servoTypId}',
      path: {
        'servoTypId': servoTypId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * @param suche
   * @param servoTypId
   * @param auftragId
   * @param sortierung Die Sortierung der Ergebnisse als komma-separierte Liste.
   * Jeder Eintrag der Liste hat die Form `[-]<feld>`, wobei das Minus eine absteigende Sortierung angibt.
   * Beispiel: `benutzerId,-zeitpunkt` bedeutet "aufsteigend nach `benutzerId`, dann absteigend nach `zeitpunkt`.
   *
   * @param seite Der 0-basierte Index der Seite. Per default wird die erste Seite geladen.
   *
   * @param max Die maximale Anzahl an Ergebnissen pro Seite.
   * Wenn dieser Parameter nicht angegeben oder negativ ist, werden alle Ergebnisse ab der angefragten Seite geladen.
   *
   * @returns ServoSeite Liste der Servos
   * @throws ApiError
   */
  public sucheServos(
    suche?: string,
    servoTypId?: ID,
    auftragId?: ID,
    sortierung?: string,
    seite?: number,
    max: number = 100,
  ): CancelablePromise<ServoSeite> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/servos',
      query: {
        'suche': suche,
        'servoTypId': servoTypId,
        'auftragId': auftragId,
        'sortierung': sortierung,
        'seite': seite,
        'max': max,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * @param servoId
   * @returns ServoMitBerechtigungen der Servo
   * @throws ApiError
   */
  public getServo(
    servoId: ID,
  ): CancelablePromise<ServoMitBerechtigungen> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/servos/{servoId}',
      path: {
        'servoId': servoId,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

}
