import { lazy, useMemo } from "react";
import { Navigate, type RouteObject } from "react-router";
import { useBerechtigt } from "../../components/Guard";

const ServoListe = lazy(() => import("./ServoListe"));
const ServoDetails = lazy(() => import("./ServoDetails"));
const ServoDaten = lazy(() => import("./ServoDaten"));
const ServoTests = lazy(() => import("./ServoTests"));

export function useServoRoutes(): Array<RouteObject> {
  const enabled = useBerechtigt({ berechtigung: "servo:liste" });

  return useMemo(
    () =>
      enabled
        ? [
            {
              path: "servos",
              children: [
                { index: true, element: <ServoListe /> },
                {
                  path: ":servoId",
                  element: <ServoDetails />,
                  children: [
                    { index: true, element: <Navigate to="daten" replace /> },
                    { path: "daten", element: <ServoDaten /> },
                    { path: "tests", element: <ServoTests /> },
                  ],
                },
              ],
            },
          ]
        : [],
    [enabled]
  );
}
