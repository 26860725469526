import { lazy, useMemo } from "react";
import { Navigate, type RouteObject } from "react-router";
import { useBerechtigt } from "../../components/Guard";

const Ausfuehrung = lazy(() => import("./Ausfuehrung"));
const AusfuehrungListe = lazy(() => import("./AusfuehrungListe"));
const AusfuehrungDaten = lazy(() => import("./AusfuehrungDaten"));
const AusfuehrungTests = lazy(() => import("./AusfuehrungTests"));

export function useAusfuehrungRoutes(): Array<RouteObject> {
  const enabled = useBerechtigt({
    berechtigung: "test-szenario-ausfuehrung:liste",
  });

  return useMemo(
    () =>
      enabled
        ? [
            {
              path: "ausfuehrungen",
              children: [
                { index: true, element: <AusfuehrungListe /> },
                {
                  path: ":testSzenarioAusfuehrungId",
                  element: <Ausfuehrung />,
                  children: [
                    { index: true, element: <Navigate to="daten" replace /> },
                    { path: "daten", element: <AusfuehrungDaten /> },
                    { path: "tests", element: <AusfuehrungTests /> },
                  ],
                },
              ],
            },
          ]
        : [],
    [enabled]
  );
}
