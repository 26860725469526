/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseHttpRequest } from './core/BaseHttpRequest';
import type { OpenAPIConfig } from './core/OpenAPI';
import { FetchHttpRequest } from './core/FetchHttpRequest';

import { AuftragService } from './services/AuftragService';
import { AusfuehrungService } from './services/AusfuehrungService';
import { BenutzerService } from './services/BenutzerService';
import { ServoService } from './services/ServoService';
import { SystemService } from './services/SystemService';
import { TestStationenService } from './services/TestStationenService';
import { TestSzenariosService } from './services/TestSzenariosService';
import { TestTypenService } from './services/TestTypenService';

type HttpRequestConstructor = new (config: OpenAPIConfig) => BaseHttpRequest;

export class Volzalyzer {

  public readonly auftrag: AuftragService;
  public readonly ausfuehrung: AusfuehrungService;
  public readonly benutzer: BenutzerService;
  public readonly servo: ServoService;
  public readonly system: SystemService;
  public readonly testStationen: TestStationenService;
  public readonly testSzenarios: TestSzenariosService;
  public readonly testTypen: TestTypenService;

  public readonly request: BaseHttpRequest;

  constructor(config?: Partial<OpenAPIConfig>, HttpRequest: HttpRequestConstructor = FetchHttpRequest) {
    this.request = new HttpRequest({
      BASE: config?.BASE ?? 'https://api.volzalyzer.com',
      VERSION: config?.VERSION ?? '1.0',
      WITH_CREDENTIALS: config?.WITH_CREDENTIALS ?? false,
      CREDENTIALS: config?.CREDENTIALS ?? 'include',
      TOKEN: config?.TOKEN,
      USERNAME: config?.USERNAME,
      PASSWORD: config?.PASSWORD,
      HEADERS: config?.HEADERS,
      ENCODE_PATH: config?.ENCODE_PATH,
    });

    this.auftrag = new AuftragService(this.request);
    this.ausfuehrung = new AusfuehrungService(this.request);
    this.benutzer = new BenutzerService(this.request);
    this.servo = new ServoService(this.request);
    this.system = new SystemService(this.request);
    this.testStationen = new TestStationenService(this.request);
    this.testSzenarios = new TestSzenariosService(this.request);
    this.testTypen = new TestTypenService(this.request);
  }
}

