import { createContext, useContext, type ReactNode } from "react";
import { useQuery } from "react-query";
import { type SystemInformationen } from "./api";
import { useUnauthenticatedAPI } from "./data";

const Context = createContext<SystemInformationen | undefined>(undefined);

export function SystemInformationenProvider({
  children,
}: {
  children: ReactNode;
}) {
  const getApi = useUnauthenticatedAPI();
  const options = useQuery(
    "systemInformationen",
    async () => (await getApi()).system.getSystemInformationen(),
    { staleTime: Infinity }
  ).data!;

  return <Context.Provider value={options}>{children}</Context.Provider>;
}

export function useSystemInformationen(): SystemInformationen {
  const context = useContext(Context);
  if (!context) {
    throw new Error(
      "useSystemInformationen must be used within a SystemInformationenProvider"
    );
  }
  return context;
}
