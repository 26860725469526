import "@fontsource-variable/open-sans";
import {
  CssBaseline,
  GlobalStyles,
  ThemeProvider as MuiThemeProvider,
  createTheme,
} from "@mui/material";
import { deDE } from "@mui/material/locale";
import { ReactNode } from "react";

const theme = createTheme(
  {
    palette: {
      primary: { main: "#1e6dba" },
      secondary: { main: "#343434" },
      background: { default: "rgb(250, 250, 250)" },
    },
    typography: {
      fontFamily: "'Open Sans Variable', sans-serif",
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: "none",
            fontSize: "1rem",
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            textTransform: "none",
            fontSize: "1rem",
          },
        },
      },
      MuiLink: {
        defaultProps: {
          underline: "none",
        },
      },
      MuiFormControl: {
        defaultProps: {
          fullWidth: true,
        },
      },
      MuiTextField: {
        defaultProps: {
          fullWidth: true,
        },
      },
      MuiCardActions: {
        styleOverrides: {
          root: {
            justifyContent: "flex-end",
          },
        },
      },
      MuiChip: {
        styleOverrides: {
          root: {
            borderRadius: "4px",
            ".MuiSvgIcon-root": {
              marginLeft: "4px",
            },
          },
        },
      },
    },
  },
  deDE
);

if (import.meta.env.DEV) console.log("Theme:", theme);

export function ThemeProvider({ children }: { children: ReactNode }) {
  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <GlobalStyles
        styles={{
          "#root": {
            minHeight: "100vh",
            overflow: "scroll-y",
            display: "flex",
            flexDirection: "column",
          },
        }}
      />
      {children}
    </MuiThemeProvider>
  );
}

export const appBarColor = "primary";
