import { ListItemButton } from "@mui/material";
import { PropsWithChildren } from "react";
import { useLocation, useResolvedPath } from "react-router";
import LinkBehavior from "./LinkBehavior";

export function NavButton({
  href,
  external,
  onClick,
  children,
}: PropsWithChildren<{
  href: string;
  external?: boolean;
  onClick?: () => void;
}>) {
  const path = useResolvedPath(href);
  let location = useLocation();
  const active = location.pathname.startsWith(path.pathname);

  return (
    <ListItemButton
      component={external ? "a" : LinkBehavior}
      href={href}
      selected={active}
      onClick={onClick}
    >
      {children}
    </ListItemButton>
  );
}
