import { customAlphabet } from "nanoid";
import { useCallback } from "react";
import { useAuth } from "react-oidc-context";
import { Volzalyzer } from "./api";
import { apiUrl, version } from "./config";

const randomString = customAlphabet(
  "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ",
  13
);

export const createId = customAlphabet(
  "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ",
  21
);

export function useGetApi(): () => Promise<Volzalyzer> {
  const getApi = useUnauthenticatedAPI();
  const getAccessToken = useGetAccessToken();

  return useCallback(async () => {
    const accessToken = await getAccessToken();
    return getApi(accessToken);
  }, [getAccessToken, getApi]);
}

export function useUnauthenticatedAPI() {
  return useCallback(async (accessToken?: string) => {
    const correlationId = Date.now().toString() + "." + randomString();

    return new Volzalyzer({
      BASE: apiUrl,
      TOKEN: accessToken,
      HEADERS: {
        "x-correlation-id": correlationId,
        "x-ui-version": version,
      },
    });
  }, []);
}

export function useGetAccessToken(): () => Promise<string | undefined> {
  const accessToken = useAuth().user?.access_token;

  return useCallback(async () => accessToken, [accessToken]);
}
