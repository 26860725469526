import { createContext, useContext, type PropsWithChildren } from "react";
import { useBenutzer } from "../benutzer";

export type Props = PropsWithChildren<{ berechtigungen: Array<string> }>;

type BerechtigungenContext = {
  berechtigungen: Array<string>;
};

const Context = createContext<BerechtigungenContext | undefined>(undefined);

export default function BerechtigungenContextProvider({
  berechtigungen,
  children,
}: Props) {
  return (
    <Context.Provider value={{ berechtigungen }}>{children}</Context.Provider>
  );
}

export function useBerechtigungen(): Array<string> {
  const { benutzer } = useBenutzer();

  return useContext(Context)?.berechtigungen ?? benutzer.globaleBerechtigungen;
}
