import { Menu } from "@mui/icons-material";
import { AppBar, Box, IconButton, Toolbar, Typography } from "@mui/material";
import Logo from "./Logo";
import NavUser from "./NavUser";

export default function Nav({
  mobile,
  open,
  setOpen,
}: {
  mobile: boolean;
  open: boolean;
  setOpen: (open: boolean) => void;
}) {
  return (
    <AppBar
      position="fixed"
      color="primary"
      elevation={0}
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
        borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
        p: 0,
      }}
    >
      <Toolbar>
        {mobile && (
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => setOpen(!open)}
          >
            <Menu />
          </IconButton>
        )}
        <Box sx={{ display: "flex", alignItems: "baseline" }}>
          <Box component={Logo} sx={{ height: 32 }} />
          <Typography variant="h5" component="div">
            alyzer
          </Typography>
        </Box>
        <Box sx={{ ml: "auto" }}>
          <NavUser />
        </Box>
      </Toolbar>
    </AppBar>
  );
}
