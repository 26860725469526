/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ID } from '../models/ID';
import type { TestTypDaten } from '../models/TestTypDaten';
import type { TestTypMitBerechtigungen } from '../models/TestTypMitBerechtigungen';
import type { TestTypSeite } from '../models/TestTypSeite';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class TestTypenService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @param seite Der 0-basierte Index der Seite. Per default wird die erste Seite geladen.
   *
   * @param max Die maximale Anzahl an Ergebnissen pro Seite.
   * Wenn dieser Parameter nicht angegeben oder negativ ist, werden alle Ergebnisse ab der angefragten Seite geladen.
   *
   * @param include
   * @returns TestTypSeite die Liste der Test-Typen
   * @throws ApiError
   */
  public sucheTestTypen(
    seite?: number,
    max: number = 100,
    include?: Array<'parameter' | 'messungen'>,
  ): CancelablePromise<TestTypSeite> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/test-typen',
      query: {
        'seite': seite,
        'max': max,
        'include': include,
      },
    });
  }

  /**
   * @param testTypId
   * @returns TestTypMitBerechtigungen der Test-Typ
   * @throws ApiError
   */
  public getTestTyp(
    testTypId: ID,
  ): CancelablePromise<TestTypMitBerechtigungen> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/test-typen/{testTypId}',
      path: {
        'testTypId': testTypId,
      },
    });
  }

  /**
   * @param testTypId
   * @param requestBody
   * @returns TestTypMitBerechtigungen der Test-Typ
   * @throws ApiError
   */
  public setzeTestTyp(
    testTypId: ID,
    requestBody: TestTypDaten,
  ): CancelablePromise<TestTypMitBerechtigungen> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/test-typen/{testTypId}',
      path: {
        'testTypId': testTypId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

}
