import {
  Navigate,
  RouterProvider,
  createBrowserRouter,
  type RouteObject,
} from "react-router-dom";
import Layout from "../components/Layout";
import { useAuftragRoutes } from "./auftraege";
import { useAusfuehrungRoutes } from "./ausfuehrungen";
import { useBenutzerRoutes } from "./benutzer";
import { useDashboardRoutes } from "./dashboard";
import { useServoTypenRoutes } from "./servo-typen";
import { useServoRoutes } from "./servos";
import { useTestStationRoutes } from "./test-stationen";
import { useTestSzenarioRoutes } from "./test-szenarios";

export default function Pages() {
  return <RouterProvider router={useRouter()} />;
}

function useRouter() {
  const routes: Array<RouteObject> = [
    {
      path: "/",
      element: <Layout />,
      children: [
        { index: true, element: <Navigate to="/auftraege" replace /> },
        ...useDashboardRoutes(),
        ...useBenutzerRoutes(),
        ...useServoTypenRoutes(),
        ...useServoRoutes(),
        ...useTestStationRoutes(),
        ...useTestSzenarioRoutes(),
        ...useAuftragRoutes(),
        ...useAusfuehrungRoutes(),
      ],
    },
  ];

  return createBrowserRouter(routes);
}
