import { createContext, useContext, type ReactNode } from "react";
import { useQuery } from "react-query";
import { type Ich } from "./api";
import { useGetApi } from "./data";

type BenutzerContext = {
  benutzer: Ich;
};

const Context = createContext<BenutzerContext | undefined>(undefined);

export function BenutzerProvider({ children }: { children: ReactNode }) {
  const benutzer = useIch();
  return <Context.Provider value={{ benutzer }}>{children}</Context.Provider>;
}

export function useBenutzer(): BenutzerContext {
  const context = useContext(Context);
  if (!context) {
    throw new Error("useBenutzer must be used within a BenutzerProvider");
  }
  return context;
}

function useIch(): Ich {
  const getApi = useGetApi();

  return useQuery("ich", async () => (await getApi()).benutzer.ich(), {
    staleTime: 5 * 60 * 1000,
  }).data!;
}
