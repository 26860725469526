import { Alert } from "@mui/material";
import { useMemo, type ReactNode } from "react";
import {
  AuthProvider as OidcAuthProvider,
  useAuth,
  type AuthProviderProps,
} from "react-oidc-context";
import GlobalLoading from "./components/GlobalLoading";
import SignIn from "./components/SignIn";
import { oidcClientId } from "./config";
import { useSystemInformationen } from "./system-informationen";

export function AuthProvider({ children }: { children: ReactNode }) {
  const { oidcAuthority } = useSystemInformationen();

  const oidcConfig: AuthProviderProps = useMemo(
    () => ({
      authority: oidcAuthority,
      client_id: oidcClientId,
      redirect_uri: `${window.origin}/`,
      scope: "openid profile email offline_access",
      onSigninCallback: () =>
        window.history.replaceState(
          {},
          document.title,
          window.location.pathname
        ),
    }),
    [oidcAuthority]
  );

  return (
    <OidcAuthProvider {...oidcConfig}>
      <AuthBouncer>{children}</AuthBouncer>
    </OidcAuthProvider>
  );
}

function AuthBouncer({ children }: { children: ReactNode }) {
  const { isLoading, error, isAuthenticated } = useAuth();
  if (isLoading) return <GlobalLoading />;
  if (error) return <Alert severity="error">{error.message}</Alert>;
  if (!isAuthenticated) return <SignIn />;
  return <>{children}</>;
}

export function useSignIn() {
  const { signinRedirect } = useAuth();
  return signinRedirect;
}
