import { LinearProgress } from "@mui/material";
import { type ReactNode } from "react";
import Global from "./Global";

export default function GlobalLoading({ children }: { children?: ReactNode }) {
  return (
    <Global>
      <LinearProgress sx={{ width: "100%" }} />
      {children}
    </Global>
  );
}
