import { Alert } from "@mui/material";
import Global from "./Global";

export default function GlobalError({ error }: { error: any }) {
  return (
    <Global>
      <Alert severity="error">{error.message}</Alert>
    </Global>
  );
}
