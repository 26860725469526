/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AktualisiereBenutzer } from '../models/AktualisiereBenutzer';
import type { BenutzerListe } from '../models/BenutzerListe';
import type { BenutzerMitBerechtigungen } from '../models/BenutzerMitBerechtigungen';
import type { Ich } from '../models/Ich';
import type { ID } from '../models/ID';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class BenutzerService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @returns Ich Der aktuell angemeldete Benutzer
   * @throws ApiError
   */
  public ich(): CancelablePromise<Ich> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ich',
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * @returns BenutzerListe Liste der Benutzer
   * @throws ApiError
   */
  public listeBenutzer(): CancelablePromise<BenutzerListe> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/benutzer',
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * @param benutzerId
   * @returns BenutzerMitBerechtigungen der Benutzer
   * @throws ApiError
   */
  public ladeBenutzer(
    benutzerId: ID,
  ): CancelablePromise<BenutzerMitBerechtigungen> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/benutzer/{benutzerId}',
      path: {
        'benutzerId': benutzerId,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * @param benutzerId
   * @param requestBody
   * @returns BenutzerMitBerechtigungen der aktualisierte Benutzer
   * @throws ApiError
   */
  public aktualisiereBenutzer(
    benutzerId: ID,
    requestBody: AktualisiereBenutzer,
  ): CancelablePromise<BenutzerMitBerechtigungen> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/benutzer/{benutzerId}',
      path: {
        'benutzerId': benutzerId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

}
