/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ErfasseTestAusfuehrungErgebnis } from '../models/ErfasseTestAusfuehrungErgebnis';
import type { ID } from '../models/ID';
import type { StarteTestSzenarioAusfuehrung } from '../models/StarteTestSzenarioAusfuehrung';
import type { TestAusfuehrungListe } from '../models/TestAusfuehrungListe';
import type { TestSzenarioAusfuehrungInfo } from '../models/TestSzenarioAusfuehrungInfo';
import type { TestSzenarioAusfuehrungSeite } from '../models/TestSzenarioAusfuehrungSeite';
import type { TestSzenarioAusfuehrungStatus } from '../models/TestSzenarioAusfuehrungStatus';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class AusfuehrungService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @param suche
   * @param auftragId
   * @param servoId
   * @param servoTypId
   * @param testStationId
   * @param ergebnis
   * @param sortierung Die Sortierung der Ergebnisse als komma-separierte Liste.
   * Jeder Eintrag der Liste hat die Form `[-]<feld>`, wobei das Minus eine absteigende Sortierung angibt.
   * Beispiel: `benutzerId,-zeitpunkt` bedeutet "aufsteigend nach `benutzerId`, dann absteigend nach `zeitpunkt`.
   *
   * @param seite Der 0-basierte Index der Seite. Per default wird die erste Seite geladen.
   *
   * @param max Die maximale Anzahl an Ergebnissen pro Seite.
   * Wenn dieser Parameter nicht angegeben oder negativ ist, werden alle Ergebnisse ab der angefragten Seite geladen.
   *
   * @returns TestSzenarioAusfuehrungSeite die Test-Szenario-Ausführung
   * @throws ApiError
   */
  public sucheTestSzenarioAusfuehrungen(
    suche?: string,
    auftragId?: ID,
    servoId?: ID,
    servoTypId?: ID,
    testStationId?: ID,
    ergebnis?: string,
    sortierung?: string,
    seite?: number,
    max: number = 100,
  ): CancelablePromise<TestSzenarioAusfuehrungSeite> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/test-szenario-ausfuehrungen',
      query: {
        'suche': suche,
        'auftragId': auftragId,
        'servoId': servoId,
        'servoTypId': servoTypId,
        'testStationId': testStationId,
        'ergebnis': ergebnis,
        'sortierung': sortierung,
        'seite': seite,
        'max': max,
      },
    });
  }

  /**
   * @param requestBody
   * @returns TestSzenarioAusfuehrungStatus die Test-Szenario-Ausführung
   * @throws ApiError
   */
  public starteTestSzenarioAusfuehrung(
    requestBody: StarteTestSzenarioAusfuehrung,
  ): CancelablePromise<TestSzenarioAusfuehrungStatus> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/test-szenario-ausfuehrungen',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @param testSzenarioAusfuehrungId
   * @returns TestSzenarioAusfuehrungInfo die Test-Szenario-Ausführung
   * @throws ApiError
   */
  public getTestSzenarioAusfuehrung(
    testSzenarioAusfuehrungId: ID,
  ): CancelablePromise<TestSzenarioAusfuehrungInfo> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/test-szenario-ausfuehrungen/{testSzenarioAusfuehrungId}',
      path: {
        'testSzenarioAusfuehrungId': testSzenarioAusfuehrungId,
      },
    });
  }

  /**
   * @param testSzenarioAusfuehrungId
   * @returns TestAusfuehrungListe die Test-Ausführung
   * @throws ApiError
   */
  public ladeTestSzenarioTestAusfuehrungen(
    testSzenarioAusfuehrungId: ID,
  ): CancelablePromise<TestAusfuehrungListe> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/test-szenario-ausfuehrungen/{testSzenarioAusfuehrungId}/test-ausfuehrungen',
      path: {
        'testSzenarioAusfuehrungId': testSzenarioAusfuehrungId,
      },
    });
  }

  /**
   * @param testSzenarioAusfuehrungId
   * @param testAusfuehrungId
   * @param requestBody
   * @returns TestSzenarioAusfuehrungStatus die Test-Ausführung
   * @throws ApiError
   */
  public erfasseTestAusfuehrungErgebnis(
    testSzenarioAusfuehrungId: ID,
    testAusfuehrungId: ID,
    requestBody: ErfasseTestAusfuehrungErgebnis,
  ): CancelablePromise<TestSzenarioAusfuehrungStatus> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/test-szenario-ausfuehrungen/{testSzenarioAusfuehrungId}/test-ausfuehrungen/{testAusfuehrungId}',
      path: {
        'testSzenarioAusfuehrungId': testSzenarioAusfuehrungId,
        'testAusfuehrungId': testAusfuehrungId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

}
