import { SVGAttributes } from "react";

export default function Logo(props: SVGAttributes<SVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 120 72.81"
      fill="currentColor"
      {...props}
    >
      <path
        d="M15.62,0l0.22,39.68L36.76,0h16.96L42.28,21.21c4.72-1,9.65-0.27,13.88,2.06l-21.1,39.99
		c-4.18-2.22-7.47-5.79-9.34-10.14L15.34,72.8H0V0H15.62z M34.07,59.13l18.61-34.45c0,0-14.94-6.43-24.12,9.88
		C20.58,48.75,34.07,59.13,34.07,59.13
    M30.79,72.81l29.9-56.7l-6.01,1.26L70.96,0.88l-3.25,12.77l-3.26,0.94l-30.7,58.22H30.79z
    M58.15,28.27c0,0,2.33,0.49,7.32,8.1l5.27-10.01h15.72L68.9,59.68h10.46l-6.93,13.13H46.25
		l1.62-3.07c-7.41,0.47-10.9-1.32-10.9-1.32l1.16-2.2c0,0,16.44,5.51,24.8-9.68s-6.08-25.81-6.08-25.81L58.15,28.27z
    M89.09,26.36H120L99.19,59.68h14.92l-6.93,13.13H74.54l20.78-33.17H82.1L89.09,26.36z"
      />
    </svg>
  );
}
