/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ErstelleTestSzenarioVersion } from '../models/ErstelleTestSzenarioVersion';
import type { ID } from '../models/ID';
import type { TestSzenarioDaten } from '../models/TestSzenarioDaten';
import type { TestSzenarioEntwurf } from '../models/TestSzenarioEntwurf';
import type { TestSzenarioEntwurfDaten } from '../models/TestSzenarioEntwurfDaten';
import type { TestSzenarioMitBerechtigungen } from '../models/TestSzenarioMitBerechtigungen';
import type { TestSzenarioSeite } from '../models/TestSzenarioSeite';
import type { TestSzenarioVersion } from '../models/TestSzenarioVersion';
import type { TestSzenarioVersionSeite } from '../models/TestSzenarioVersionSeite';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class TestSzenariosService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @param sortierung Die Sortierung der Ergebnisse als komma-separierte Liste.
   * Jeder Eintrag der Liste hat die Form `[-]<feld>`, wobei das Minus eine absteigende Sortierung angibt.
   * Beispiel: `benutzerId,-zeitpunkt` bedeutet "aufsteigend nach `benutzerId`, dann absteigend nach `zeitpunkt`.
   *
   * @param seite Der 0-basierte Index der Seite. Per default wird die erste Seite geladen.
   *
   * @param max Die maximale Anzahl an Ergebnissen pro Seite.
   * Wenn dieser Parameter nicht angegeben oder negativ ist, werden alle Ergebnisse ab der angefragten Seite geladen.
   *
   * @returns TestSzenarioSeite die Liste der Test-Szenarios
   * @throws ApiError
   */
  public sucheTestSzenarios(
    sortierung?: string,
    seite?: number,
    max: number = 100,
  ): CancelablePromise<TestSzenarioSeite> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/test-szenarios',
      query: {
        'sortierung': sortierung,
        'seite': seite,
        'max': max,
      },
    });
  }

  /**
   * @param testSzenarioId
   * @returns TestSzenarioMitBerechtigungen das Test-Szenario
   * @throws ApiError
   */
  public getTestSzenario(
    testSzenarioId: ID,
  ): CancelablePromise<TestSzenarioMitBerechtigungen> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/test-szenarios/{testSzenarioId}',
      path: {
        'testSzenarioId': testSzenarioId,
      },
    });
  }

  /**
   * @param testSzenarioId
   * @param requestBody
   * @returns TestSzenarioMitBerechtigungen das Test-Szenario
   * @throws ApiError
   */
  public setzeTestSzenario(
    testSzenarioId: ID,
    requestBody: TestSzenarioDaten,
  ): CancelablePromise<TestSzenarioMitBerechtigungen> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/test-szenarios/{testSzenarioId}',
      path: {
        'testSzenarioId': testSzenarioId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @param suche
   * @param servoTypId
   * @param testSzenarioId
   * @param aktuell
   * @param sortierung Die Sortierung der Ergebnisse als komma-separierte Liste.
   * Jeder Eintrag der Liste hat die Form `[-]<feld>`, wobei das Minus eine absteigende Sortierung angibt.
   * Beispiel: `benutzerId,-zeitpunkt` bedeutet "aufsteigend nach `benutzerId`, dann absteigend nach `zeitpunkt`.
   *
   * @param seite Der 0-basierte Index der Seite. Per default wird die erste Seite geladen.
   *
   * @param max Die maximale Anzahl an Ergebnissen pro Seite.
   * Wenn dieser Parameter nicht angegeben oder negativ ist, werden alle Ergebnisse ab der angefragten Seite geladen.
   *
   * @returns TestSzenarioVersionSeite die Liste der Test-Szenarios
   * @throws ApiError
   */
  public sucheTestSzenarioVersionen(
    suche?: string,
    servoTypId?: ID,
    testSzenarioId?: ID,
    aktuell?: boolean,
    sortierung?: string,
    seite?: number,
    max: number = 100,
  ): CancelablePromise<TestSzenarioVersionSeite> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/test-szenario-versionen',
      query: {
        'suche': suche,
        'servoTypId': servoTypId,
        'testSzenarioId': testSzenarioId,
        'aktuell': aktuell,
        'sortierung': sortierung,
        'seite': seite,
        'max': max,
      },
    });
  }

  /**
   * @param testSzenarioId
   * @param sortierung Die Sortierung der Ergebnisse als komma-separierte Liste.
   * Jeder Eintrag der Liste hat die Form `[-]<feld>`, wobei das Minus eine absteigende Sortierung angibt.
   * Beispiel: `benutzerId,-zeitpunkt` bedeutet "aufsteigend nach `benutzerId`, dann absteigend nach `zeitpunkt`.
   *
   * @param seite Der 0-basierte Index der Seite. Per default wird die erste Seite geladen.
   *
   * @param max Die maximale Anzahl an Ergebnissen pro Seite.
   * Wenn dieser Parameter nicht angegeben oder negativ ist, werden alle Ergebnisse ab der angefragten Seite geladen.
   *
   * @returns TestSzenarioVersionSeite die Liste der Test-Szenario-Versionen
   * @throws ApiError
   */
  public ladeTestSzenarioVersionen(
    testSzenarioId: ID,
    sortierung?: string,
    seite?: number,
    max: number = 100,
  ): CancelablePromise<TestSzenarioVersionSeite> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/test-szenarios/{testSzenarioId}/versionen',
      path: {
        'testSzenarioId': testSzenarioId,
      },
      query: {
        'sortierung': sortierung,
        'seite': seite,
        'max': max,
      },
    });
  }

  /**
   * @param testSzenarioId
   * @param requestBody
   * @returns TestSzenarioVersion die Test-Szenario-Version
   * @throws ApiError
   */
  public erstelleTestSzenarioVersion(
    testSzenarioId: ID,
    requestBody: ErstelleTestSzenarioVersion,
  ): CancelablePromise<TestSzenarioVersion> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/test-szenarios/{testSzenarioId}/versionen',
      path: {
        'testSzenarioId': testSzenarioId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @param testSzenarioVersionId
   * @returns TestSzenarioVersion die Test-Szenario-Version
   * @throws ApiError
   */
  public getTestSzenarioVersion(
    testSzenarioVersionId: ID,
  ): CancelablePromise<TestSzenarioVersion> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/test-szenario-versionen/{testSzenarioVersionId}',
      path: {
        'testSzenarioVersionId': testSzenarioVersionId,
      },
    });
  }

  /**
   * @param testSzenarioId
   * @returns TestSzenarioEntwurf der Test-Szenario-Entwurf
   * @throws ApiError
   */
  public getTestSzenarioEntwurf(
    testSzenarioId: ID,
  ): CancelablePromise<TestSzenarioEntwurf> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/test-szenarios/{testSzenarioId}/entwurf',
      path: {
        'testSzenarioId': testSzenarioId,
      },
    });
  }

  /**
   * @param testSzenarioId
   * @param requestBody
   * @returns TestSzenarioEntwurf der Test-Szenario-Entwurf
   * @throws ApiError
   */
  public speichereTestSzenarioEntwurf(
    testSzenarioId: ID,
    requestBody: TestSzenarioEntwurfDaten,
  ): CancelablePromise<TestSzenarioEntwurf> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/test-szenarios/{testSzenarioId}/entwurf',
      path: {
        'testSzenarioId': testSzenarioId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

}
