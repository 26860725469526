import {
  Avatar,
  Button,
  DialogActions,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Popover,
} from "@mui/material";
import {
  bindPopover,
  bindTrigger,
  usePopupState,
} from "material-ui-popup-state/hooks";
import { useAuth } from "react-oidc-context";
import { useBenutzer } from "../benutzer";

export default function NavUser() {
  const { benutzer } = useBenutzer();
  const popupState = usePopupState({ variant: "popover", popupId: "user" });
  const { signoutRedirect } = useAuth();

  return (
    <>
      <IconButton {...bindTrigger(popupState)} edge="end" size="small">
        <Avatar>{(benutzer.name ?? benutzer.mail ?? benutzer.id)[0]}</Avatar>
      </IconButton>
      <Popover
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        {...bindPopover(popupState)}
      >
        <List>
          <ListItem>
            <ListItemText
              primary={benutzer.name ?? benutzer.mail ?? benutzer.id}
              secondary={
                benutzer.name ? benutzer.mail ?? benutzer.id : benutzer.id
              }
            />
          </ListItem>
        </List>
        <DialogActions>
          <Button
            onClick={() =>
              signoutRedirect({ post_logout_redirect_uri: window.origin })
            }
          >
            Abmelden
          </Button>
        </DialogActions>
      </Popover>
    </>
  );
}
