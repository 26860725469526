import { lazy, useMemo } from "react";
import { type RouteObject } from "react-router";
import { useBerechtigt } from "../../components/Guard";

const ServoTypListe = lazy(() => import("./ServoTypListe"));
const ServoTypDialog = lazy(() => import("./ServoTypDialog"));

export function useServoTypenRoutes(): Array<RouteObject> {
  const enabled = useBerechtigt({ berechtigung: "servo-typ:liste" });

  return useMemo(
    () =>
      enabled
        ? [
            {
              path: "servo-typen",
              element: <ServoTypListe />,
              children: [
                { path: "neu", element: <ServoTypDialog /> },
                { path: ":servoTypId", element: <ServoTypDialog /> },
              ],
            },
          ]
        : [],
    [enabled]
  );
}
