import { LinearProgress } from "@mui/material";
import { ComponentType, ReactNode, Suspense } from "react";
import { ErrorBoundary, FallbackProps } from "react-error-boundary";
import FallbackAlert from "./FallbackAlert";

export default function SuspenseBoundary({
  fallback,
  errorComp = FallbackAlert,
  children,
}: {
  fallback?: ReactNode;
  errorComp?: ComponentType<FallbackProps>;
  children: ReactNode;
}) {
  return (
    <Suspense fallback={fallback ?? <LinearProgress />}>
      <ErrorBoundary FallbackComponent={errorComp}>{children}</ErrorBoundary>
    </Suspense>
  );
}
