import { lazy, useMemo } from "react";
import { type RouteObject } from "react-router";
import { useBerechtigt } from "../../components/Guard";
import { PERMISSION_BENUTZER_LISTE } from "../../permissions";

const BenutzerListe = lazy(() => import("./BenutzerListe"));
const BenutzerDetails = lazy(() => import("./BenutzerDetails"));

export function useBenutzerRoutes(): Array<RouteObject> {
  const enabled = useBerechtigt({ berechtigung: PERMISSION_BENUTZER_LISTE });

  return useMemo(
    () =>
      enabled
        ? [
            {
              path: "/benutzer",
              children: [
                { index: true, element: <BenutzerListe /> },
                { path: ":id", element: <BenutzerDetails /> },
              ],
            },
          ]
        : [],
    [enabled]
  );
}
