import { lazy, useMemo } from "react";
import { type RouteObject } from "react-router";

const Dashboard = lazy(() => import("./Dashboard"));

export function useDashboardRoutes(): Array<RouteObject> {
  return useMemo(
    () => [
      {
        path: "/startseite",
        element: <Dashboard />,
      },
    ],
    []
  );
}
