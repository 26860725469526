/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AuftragMitBerechtigungen } from '../models/AuftragMitBerechtigungen';
import type { AuftragSeite } from '../models/AuftragSeite';
import type { ID } from '../models/ID';
import type { SetzeAuftrag } from '../models/SetzeAuftrag';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class AuftragService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @param suche
   * @param kunde
   * @param servoTypId
   * @param testSzenarioId
   * @param testSzenarioVersionId
   * @param sortierung Die Sortierung der Ergebnisse als komma-separierte Liste.
   * Jeder Eintrag der Liste hat die Form `[-]<feld>`, wobei das Minus eine absteigende Sortierung angibt.
   * Beispiel: `benutzerId,-zeitpunkt` bedeutet "aufsteigend nach `benutzerId`, dann absteigend nach `zeitpunkt`.
   *
   * @param seite Der 0-basierte Index der Seite. Per default wird die erste Seite geladen.
   *
   * @param max Die maximale Anzahl an Ergebnissen pro Seite.
   * Wenn dieser Parameter nicht angegeben oder negativ ist, werden alle Ergebnisse ab der angefragten Seite geladen.
   *
   * @returns AuftragSeite Liste der Auftraege
   * @throws ApiError
   */
  public sucheAuftraege(
    suche?: string,
    kunde?: ID,
    servoTypId?: ID,
    testSzenarioId?: ID,
    testSzenarioVersionId?: ID,
    sortierung?: string,
    seite?: number,
    max: number = 100,
  ): CancelablePromise<AuftragSeite> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/auftraege',
      query: {
        'suche': suche,
        'kunde': kunde,
        'servoTypId': servoTypId,
        'testSzenarioId': testSzenarioId,
        'testSzenarioVersionId': testSzenarioVersionId,
        'sortierung': sortierung,
        'seite': seite,
        'max': max,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * @param auftragId
   * @returns AuftragMitBerechtigungen der Auftrag
   * @throws ApiError
   */
  public getAuftrag(
    auftragId: ID,
  ): CancelablePromise<AuftragMitBerechtigungen> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/auftraege/{auftragId}',
      path: {
        'auftragId': auftragId,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * @param auftragId
   * @param requestBody
   * @returns AuftragMitBerechtigungen der aktualisierte Auftrag
   * @throws ApiError
   */
  public setzeAuftrag(
    auftragId: ID,
    requestBody: SetzeAuftrag,
  ): CancelablePromise<AuftragMitBerechtigungen> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/auftraege/{auftragId}',
      path: {
        'auftragId': auftragId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

}
