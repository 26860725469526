import { lazy, useMemo } from "react";
import { Navigate, type RouteObject } from "react-router";
import { useBerechtigt } from "../../components/Guard";

const AuftragListe = lazy(() => import("./AuftragListe"));
const AuftragDetails = lazy(() => import("./AuftragDetails"));
const AuftragDetailsDaten = lazy(() => import("./AuftragDetailsDaten"));
const AuftragTests = lazy(() => import("./AuftragTests"));
const AuftragServos = lazy(() => import("./AuftragServos"));

export function useAuftragRoutes(): Array<RouteObject> {
  const enabled = useBerechtigt({ berechtigung: "auftrag:liste" });

  return useMemo(
    () =>
      enabled
        ? [
            {
              path: "auftraege",
              children: [
                { index: true, element: <AuftragListe /> },
                { path: "neu", element: <AuftragListe /> },
                {
                  path: ":auftragId",
                  element: <AuftragDetails />,
                  children: [
                    { index: true, element: <Navigate to="daten" replace /> },
                    { path: "daten", element: <AuftragDetailsDaten /> },
                    { path: "servos", element: <AuftragServos /> },
                    { path: "tests", element: <AuftragTests /> },
                  ],
                },
              ],
            },
          ]
        : [],
    [enabled]
  );
}
