import { ComponentType, type PropsWithChildren } from "react";
import {
  HelmetProvider as Provider,
  type ProviderProps,
} from "react-helmet-async";

// There are some weird typing issues with react-helmet-async,
// so we need to provide this wrapper to get around them.
export default function HelmetProvider(
  props: PropsWithChildren<ProviderProps>
) {
  const P = Provider as unknown as ComponentType<
    PropsWithChildren<ProviderProps>
  >;

  return <P {...props} />;
}
